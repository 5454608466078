import './index.css';
// import './publicPath';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import defaultMessages from './lang/en.json';

if (!document.getElementById('react_app')) {
  const divEle = document.createElement('div');
  divEle.id = 'react_app';
  document.body.appendChild(divEle);
}
ReactDOM.render(
  <React.StrictMode>
    <App messages={defaultMessages} />
  </React.StrictMode>, 
  document.getElementById('react_app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
