import React, { Suspense } from 'react';
// import QuickSecurityCheck from './components/QuickSecurityCheck';
// import HolidaySurvey from './components/Survey';
import {IntlProvider} from 'react-intl';

let loadQuickSecurityCheck, returnQuickSecurityCheck;
const QuickSecurityCheck = React.lazy(() => new Promise(resolve => loadQuickSecurityCheck = resolve).then(() => import('./components/QuickSecurityCheck')));

let loadHolidaySurvey, returnHolidaySurvey;
const HolidaySurvey = React.lazy(() => new Promise(resolve => loadHolidaySurvey = resolve).then(() => import('./components/Survey')));

const lang_locale = {
  'english': 'en',
  'chinese': 'zh',
  'spanish': 'es',
  'french': 'fr',
  'german': 'de',
  'japanese': 'ja',
  'korean': 'ko',
  'portuguese': 'pt',
  'italian': 'it',
  'dutch': 'nl',
  'swedish': 'sv',
};

function App(props) {
  // console.log('App:',props);
  const [messages, setMessages] = React.useState(props.messages);
  const [paramsQuickSecurityCheck, setParamsQuickSecurityCheck] = React.useState({});
  const [paramsHolidaySurvey, setParamsHolidaySurvey] = React.useState({});
  // const [locale, setLocale] = React.useState('en');
  window.setReactLang = async (lang) => {
    if ('en'!==lang && lang_locale[lang]) {
      const newMessages = await import(`./lang/${lang_locale[lang]}.json`);
      setMessages({...messages, ...newMessages});
      // setLocale(lang);
    }
  };
  window.handleOpenSecurityCheckDialog = async (props) => {
    console.log('handleOpenSecurityCheckDialog:',props);
    loadQuickSecurityCheck();
    setParamsQuickSecurityCheck(props);
    return new Promise(resolve => returnQuickSecurityCheck = resolve);
  };
  window.handleOpenHolidaySurveyDialog = async (props) => {
    console.log('handleOpenHolidaySurveyDialog:',props);
    loadHolidaySurvey();
    setParamsHolidaySurvey(props);
    return new Promise(resolve => returnHolidaySurvey = resolve);
  };


  return (
    <IntlProvider messages={messages} locale="en" defaultRichTextElements={{
      b: (chunks) => <b>{chunks}</b>,
      p: (chunks) => <p>{chunks}</p>,
      br: () => <br/>,
    }}>
      <Suspense fallback={<></>}>
        <HolidaySurvey callparams={paramsHolidaySurvey} callback={(msg)=>returnHolidaySurvey(msg)}/>
      </Suspense>
      <Suspense fallback={<></>}>
        <QuickSecurityCheck callparams={paramsQuickSecurityCheck} callback={(msg)=>returnQuickSecurityCheck(msg)}/>
      </Suspense>
    </IntlProvider>
  );
}

export default App;
